<template>
  <main
    style="
      height: 100vh;
      max-height: 600px;
      width: 450px;
      margin: auto;
      position: relative;
    "
    @click="closeDropdown()"
  >
    <nav class="navbar navbar-default navbar-static-top">
      <a class="navbar-brand" href="#">
        <img
          style="margin: auto; user-select: none"
          src="./assets/callingly-small-white2.png"
        />
      </a>

      <ul v-if="user" class="nav navbar-right">
        <li class="dropdown" :class="{ open: isDropdownOpen }">
          <a
            style="
              user-select: none;
              height: 69px;
              display: flex;
              align-items: center;
              background-color: transparent !important;
            "
            href="#"
            class="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            @click.stop="toggleDropdown"
          >
            <img
              id="profile"
              class="img-responsive pull-left"
              :class="{ online: online, offline: !online }"
              style="
                height: 36px;
                width: 36px;
                border-radius: 50%;
                margin-right: 10px;
              "
              :src="user.profile_picture"
            />
            <span style="color: #a7b1c2"
              ><span
                style="
                  max-width: 150px;
                  overflow: hidden;
                  float: left;
                  white-space: nowrap;
                "
                >{{ user.full_name }}</span
              >
              <i
                style="
                  font-size: 18px;
                  margin-left: 5px;
                  margin-right: 10px;
                  float: right;
                  margin-top: 1px;
                "
                class="material-icons"
                >keyboard_arrow_down</i
              ></span
            >
          </a>

          <ul
            class="dropdown-menu add-triangle dropdown-menu-right"
            style="user-select: none; margin-right: 20px; min-width: 300px"
            @click.stop
          >
            <li style="padding: 10px 20px" class="clearfix">
              <img
                class="img-responsive pull-left"
                style="max-height: 80px; border-radius: 50%; margin-right: 15px"
                :src="user.profile_picture"
              />
              <div
                style="
                  font-size: 20px;
                  margin-top: 0px;
                  color: #073b4c;
                  font-weight: bold;
                "
              >
                {{ user.full_name }}
              </div>
              <div
                style="
                  float: left;
                  font-size: 13px;
                  color: #a7b1c2;
                  margin-top: 5px;
                "
              >
                {{ user.email }}
              </div>
              <div
                style="
                  float: left;
                  font-size: 13px;
                  color: #a7b1c2;
                  margin-top: 5px;
                "
              >
                {{ user.phone_number_formatted }}
              </div>
            </li>

            <li role="separator" class="divider" />
            <li style="padding-bottom: 5px">
              <a href="#" @click="logout">Log out</a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>

    <div
      class="main-container"
      style="background-size: cover; height: 530px; padding-top: 20px"
    >
      <div v-if="status == 'login'">
        <h1 style="font-weight: 300; padding-bottom: 40px" class="text-center">
          Login
        </h1>

        <div
          v-if="error_message"
          class="help-block text-center"
          style="color: #a94442; margin-top: -30px; margin-bottom: 30px"
        >
          <strong>{{ error_message }} </strong>
        </div>

        <form
          class="login"
          role="form"
          method="POST"
          style="max-width: 400px; margin: auto"
          @submit.prevent="login"
        >
          <div class="form-group">
            <input
              v-model="email"
              type="email"
              name="email"
              value=""
              required
              autofocus
            />
            <span class="bar" />
            <label for="email">E-Mail Address</label>
          </div>

          <div class="form-group">
            <input
              v-model="password"
              type="password"
              name="password"
              required
            />
            <span class="bar" />
            <label for="password">Password</label>
          </div>

          <div class="form-group text-right">
            <button
              type="submit"
              class="btn btn-lg btn-primary callingly-button-color"
            >
              Login
            </button>
          </div>

          <a
            href="https://callingly.com/apps/workos/google"
            class="btn btn-default btn-sign-up btn-sign-up-google btn-lg rounded"
            style="position: relative; top: 70px"
          >
            <img
              src="./assets/google-sign-in.png"
              class="btn-sign-up-google__img"
              alt="Google logo"
            />
            Sign in with Google
          </a>
        </form>
      </div>

      <div v-if="user">
        <div
          class="text-center"
          v-if="
            !isDialpadOpen &&
            !isTransferOpen &&
            !isConferenceLeadOpen &&
            !openTab
          "
        >
          <img
            v-if="lead"
            class="img-responsive"
            style="
              user-select: none;
              height: 80px;
              width: 80px;
              border-radius: 50%;
              margin: auto;
            "
            :src="lead.profile_picture"
          />

          <h3>
            {{ lead.label }}
          </h3>
          <h3 v-if="status == 'waiting'">Waiting for lead...</h3>

          <button
            v-if="status == 'waiting' && !lead.phone_number"
            type="button"
            class="btn btn-default"
            style="margin-top: 20px; padding: 6px 12px 6px 10px"
            @click="check()"
          >
            <i class="material-icons" style="font-size: 23px; float: left"
              >person</i
            ><span style="margin-left: 7px; position: relative; top: 1px"
              >Check for lead</span
            >
          </button>

          <p v-if="status != 'waiting'" style="font-size: 16px">
            {{ message }}
          </p>
          <!-- <p
            v-if="call_user.type == 'barge' || call_user.type == 'whisper' || call_user.type == 'listen' || call.type == 'transfer' || call.type == 'conference'"
            style="font-size: 16px;"            
          >
            With {{ call.user.full_name }}
          </p>					 -->

          <div
            style="margin-top: 20px; margin-bottom: 20px; text-align: center"
          >
            <h4 style="font-style: italic">
              {{ lead.company }}
            </h4>
            <h4 v-if="call.account_id != 6671">
              {{ lead.email }}
            </h4>
            <h4>
              {{ lead.category }}
            </h4>
            <a
              v-if="lead.crm == 'hubspot'"
              target="_blank"
              class="btn btn-default"
              :href="
                'https://app.hubspot.com/contacts/' +
                user.integrations.hubspot_id +
                '/contact/' +
                lead.source_id +
                '/'
              "
              >Hubspot &nbsp;<i
                style="font-size: 18px; float: right; margin-top: 1px"
                class="material-icons"
                >link</i
              ></a
            >
            <a
              v-if="lead.crm == 'salesforce'"
              target="_blank"
              class="btn btn-default"
              :href="user.integrations.salesforce_domain + '/' + lead.source_id"
              >Salesforce &nbsp;<i
                style="font-size: 18px; float: right; margin-top: 1px"
                class="material-icons"
                >link</i
              ></a
            >
            <a
              v-if="lead.crm == 'activecampaign'"
              target="_blank"
              class="btn btn-default"
              :href="
                user.integrations.activecampaign_domain +
                '/app/contacts/' +
                lead.source_id
              "
              >ActiveCampaign &nbsp;<i
                style="font-size: 18px; float: right; margin-top: 1px"
                class="material-icons"
                >link</i
              ></a
            >
            <a
              v-if="call.account_id == 6671"
              target="_blank"
              class="btn btn-default"
              :href="
                'https://crm.alige.com.mx?agId=' +
                user.id +
                '&clId=' +
                lead.id +
                '&tel=' +
                lead.phone_number +
                '&callId=' +
                call.id
              "
              >Alige &nbsp;<i
                style="font-size: 18px; float: right; margin-top: 1px"
                class="material-icons"
                >link</i
              ></a
            >
            <a
              v-if="call.account_id == 6489"
              target="_blank"
              class="btn btn-default"
              :href="
                'https://pruebacrm.alige.com.mx?agId=' +
                user.id +
                '&clId=' +
                lead.id +
                '&tel=' +
                lead.phone_number +
                '&callId=' +
                call.id
              "
              >Alige &nbsp;<i
                style="font-size: 18px; float: right; margin-top: 1px"
                class="material-icons"
                >link</i
              ></a
            >
          </div>
        </div>

        <div
          v-if="!isConferenceLeadOpen"
          id="buttons"
          style="width: fit-content; margin: auto"
          class="clearfix"
        >
          <div
            v-if="status == 'ringing'"
            style="width: 70px; float: left; margin: 0 45px"
            @click="accept()"
          >
            <div
              class="text-center"
              style="
                cursor: pointer;
                width: 70px;
                height: 70px;
                border-radius: 50%;
                border: 2px solid #5cb85c;
                margin: auto;
              "
            >
              <i
                class="material-icons"
                style="color: #5cb85c; font-size: 40px; line-height: 65px"
                >call</i
              >
            </div>
            <p style="font-size: 14px; text-align: center; padding-top: 10px">
              {{
                call.direction == "inbound" || call.type == "transfer"
                  ? "Accept"
                  : "Call"
              }}
            </p>
          </div>

          <div
            v-if="status == 'ringing'"
            style="width: 70px; float: left; margin: 0 45px"
            @click="reject()"
          >
            <div
              class="text-center"
              style="
                cursor: pointer;
                width: 70px;
                height: 70px;
                border-radius: 50%;
                border: 2px solid #eb0000;
                margin: auto;
              "
            >
              <i
                class="material-icons"
                style="color: #eb0000; font-size: 40px; line-height: 65px"
                >call_end</i
              >
            </div>
            <p style="font-size: 14px; text-align: center; padding-top: 10px">
              Ignore
            </p>
          </div>

          <div
            v-if="
              status == 'oncall' &&
              call.lead_id &&
              call_user.type != 'listen' &&
              call_user.type != 'whisper'
            "
            class="text-center"
            style="cursor: pointer; width: 70px; float: right; margin: 0 10px"
            @click="toggleTransfer()"
          >
            <div class="btn-dialer">
              <i
                class="material-icons"
                style="
                  color: #073b4c;
                  font-size: 40px;
                  line-height: 69px;
                  margin-left: -2px;
                "
                >add_ic_call</i
              >
            </div>
            <p style="font-size: 14px; text-align: center; padding-top: 10px">
              Transfer
            </p>
          </div>

          <div
            v-if="status == 'oncall' && !call.lead_id"
            class="text-center"
            style="cursor: pointer; width: 70px; float: right; margin: 0 10px"
            @click="toggleConferenceLead()"
          >
            <div class="btn-dialer">
              <i
                class="material-icons"
                style="
                  color: #073b4c;
                  font-size: 40px;
                  line-height: 69px;
                  margin-left: 3px;
                  margin-top: -2px;
                "
                >person_add_alt_1</i
              >
            </div>
            <p style="font-size: 14px; text-align: center; padding-top: 10px">
              Add Lead
            </p>
          </div>

          <div
            v-if="
              status == 'oncall' &&
              call_user.type != 'listen' &&
              call_user.type != 'whisper'
            "
            class="text-center"
            style="cursor: pointer; width: 70px; float: right; margin: 0 10px"
            @click="toggleDialpad()"
          >
            <div class="btn-dialer">
              <i
                class="material-icons"
                style="color: #073b4c; font-size: 40px; line-height: 70px"
                >dialpad</i
              >
            </div>
            <p style="font-size: 14px; text-align: center; padding-top: 10px">
              Keypad
            </p>
          </div>

          <div
            v-if="status == 'oncall' && !isMuted"
            class="text-center"
            style="cursor: pointer; width: 70px; float: right; margin: 0 10px"
            @click="mute()"
          >
            <div class="btn-dialer">
              <i
                class="material-icons"
                style="color: #073b4c; font-size: 40px; line-height: 65px"
                >volume_up</i
              >
            </div>
            <p style="font-size: 14px; text-align: center; padding-top: 10px">
              Mute
            </p>
          </div>

          <div
            v-if="status == 'oncall' && isMuted"
            :class="{ disabled: call_user.type == 'listen' }"
            class="text-center"
            style="cursor: pointer; width: 70px; float: right; margin: 0 10px"
            @click="unmute()"
          >
            <div class="btn-dialer">
              <i
                class="material-icons"
                style="color: #073b4c; font-size: 40px; line-height: 65px"
                >volume_off</i
              >
            </div>
            <p style="font-size: 14px; text-align: center; padding-top: 10px">
              Muted
            </p>
          </div>

          <div
            v-if="status == 'oncall'"
            class="text-center"
            style="cursor: pointer; width: 70px; float: right; margin: 0 10px"
            @click="hangup()"
          >
            <div
              style="
                width: 70px;
                height: 70px;
                border-radius: 50%;
                border: 2px solid #eb0000;
                margin: auto;
              "
            >
              <i
                class="material-icons"
                style="color: #eb0000; font-size: 40px; line-height: 65px"
                >call_end</i
              >
            </div>
            <p style="font-size: 14px; text-align: center; padding-top: 10px">
              Hang Up
            </p>
          </div>
        </div>

        <div
          v-if="status == 'disposition' && !isDialpadOpen && !openTab"
          style="margin-top: 10px; margin-bottom: 30px; text-align: center"
        >
          <h3
            style="font-weight: 400; margin-bottom: 20px; margin-top: 0"
            class="text-center"
          >
            Call Result
          </h3>

          <button
            type="button"
            class="btn btn-default btn-disposition"
            style="background-color: transparent"
            @click="disposition('contacted')"
          >
            <i class="material-icons" style="color: #5cb85c; font-size: 25px"
              >how_to_reg</i
            ><span
              style="line-height: 26px; vertical-align: top; margin-left: 7px"
              >Contacted</span
            >
          </button>

          <button
            type="button"
            class="btn btn-default btn-disposition"
            style="background-color: transparent"
            @click="disposition('voicemail')"
          >
            <i class="material-icons" style="color: #f0ad4e; font-size: 25px"
              >voicemail</i
            >
            <span
              style="line-height: 26px; vertical-align: top; margin-left: 7px"
              >Voicemail</span
            >
          </button>

          <button
            type="button"
            class="btn btn-default btn-disposition"
            style="background-color: transparent"
            @click="disposition('missed')"
          >
            <i class="material-icons" style="color: #d9534f; font-size: 25px"
              >call_missed_outgoing</i
            >
            <span
              style="line-height: 26px; vertical-align: top; margin-left: 7px"
              >Missed</span
            >
          </button>
          <br />
          <button
            type="button"
            class="btn btn-default btn-disposition"
            style="
              background-color: transparent;
              margin-top: 10px;
              padding-top: 5px;
              padding-bottom: 2px;
              padding-left: 10px;
              padding-right: 15px;
            "
            @click="disposition('removed')"
          >
            <i class="material-icons" style="color: #d9534f; font-size: 25px"
              >close</i
            >
            <span
              style="line-height: 26px; vertical-align: top; margin-left: 2px"
              >Remove</span
            >
          </button>

          <br />

          <button
            v-if="user.permissions.includes('app-retry')"
            type="button"
            class="btn btn-default btn-disposition"
            style="background-color: transparent; margin-top: 5px"
            @click="retry"
          >
            <i
              class="material-icons"
              style="color: #073b4c; font-size: 18px; line-height: 1.4"
              >repeat</i
            >
            <span
              style="line-height: 23px; vertical-align: top; margin-left: 7px"
              >Retry</span
            >
          </button>
          <button
            v-if="user.permissions.includes('app-sms')"
            type="button"
            class="btn btn-default btn-disposition"
            style="background-color: transparent; margin-top: 5px"
            @click="showsms"
          >
            <i
              class="material-icons"
              style="color: #073b4c; font-size: 18px; line-height: 1.4"
              >sms</i
            >
            <span
              style="line-height: 23px; vertical-align: top; margin-left: 7px"
              >SMS</span
            >
          </button>
        </div>

        <div
          v-if="status == 'sms' && !openTab"
          style="margin-top: 10px; margin-bottom: 30px; text-align: center"
        >
          <h3
            style="font-weight: 400; margin-bottom: 20px; margin-top: 0"
            class="text-center"
          >
            Send SMS
          </h3>

          <textarea
            v-model="smsbody"
            style="width: 90%; margin: auto; margin-bottom: 10px"
            class="form-control"
          />

          <button
            type="button"
            class="btn btn-default btn-disposition"
            style="background-color: transparent; margin-top: 5px"
            @click="sendsms"
          >
            <i
              class="material-icons"
              style="color: #5cb85c; font-size: 18px; line-height: 1.4"
              >send</i
            >
            <span
              style="line-height: 23px; vertical-align: top; margin-left: 7px"
              >Send</span
            >
          </button>
          <button
            type="button"
            class="btn btn-default btn-disposition"
            style="background-color: transparent; margin-top: 5px"
            @click="thankyou"
          >
            <i
              class="material-icons"
              style="color: #d9534f; font-size: 18px; line-height: 1.4"
              >cancel</i
            >
            <span
              style="line-height: 23px; vertical-align: top; margin-left: 7px"
              >Cancel</span
            >
          </button>
        </div>

        <div
          v-if="status == 'disposition2'"
          style="margin-top: 10px; margin-bottom: 30px; text-align: center"
        >
          <h3
            style="font-weight: 400; margin-bottom: 20px; margin-top: 0"
            class="text-center"
          >
            Tags & Stages
          </h3>

          <button
            v-for="tag in call.profile.tags"
            :key="tag.id"
            style="padding-top: 6px; border: none; color: white; margin: 5px"
            type="button"
            class="btn btn-default btn-disposition"
            :style="{ backgroundColor: tag.color }"
            @click="disposition2(tag.key)"
          >
            {{ tag.name }}
          </button>

          <div
            style="
              cursor: pointer;
              margin-top: 10px;
              text-decoration: underline;
              color: grey;
            "
            @click="thankyou()"
          >
            Skip
          </div>
        </div>

        <h3
          v-if="status == 'thankyou'"
          style="margin-top: 30px; font-weight: 400"
          class="text-center"
        >
          Thank you!
        </h3>

        <div
          v-if="isDialpadOpen"
          style="
            color: white;
            width: fit-content;
            margin: auto;
            margin-top: 30px;
            overflow: hidden;
            height: auto;
          "
        >
          <div>
            <a class="btn btn-number" @click="digitPress('1')">1</a>
            <a class="btn btn-number" @click="digitPress('2')">2</a>
            <a class="btn btn-number" @click="digitPress('3')">3</a>
          </div>
          <div style="margin-top: 10px">
            <a class="btn btn-number" @click="digitPress('4')">4</a>
            <a class="btn btn-number" @click="digitPress('5')">5</a>
            <a class="btn btn-number" @click="digitPress('6')">6</a>
          </div>
          <div style="margin-top: 10px">
            <a class="btn btn-number" @click="digitPress('7')">7</a>
            <a class="btn btn-number" @click="digitPress('8')">8</a>
            <a class="btn btn-number" @click="digitPress('9')">9</a>
          </div>
          <div style="margin-top: 10px">
            <a class="btn btn-number" @click="digitPress('*')"
              ><span style="position: relative; top: 3px">*</span></a
            >
            <a class="btn btn-number" @click="digitPress('0')">0</a>
            <a class="btn btn-number" @click="digitPress('#')">#</a>
          </div>
        </div>

        <div v-if="isTransferOpen">
          <div
            style="
              padding-top: 15px;
              padding-left: 0;
              padding-right: 0;
              height: 430px;
              overflow: none;
              margin-top: -30px;
            "
          >
            <div style="text-align: center; font-weight: bold">
              Transfer to
              <select
                v-model="transfer_type"
                class="form-control"
                style="
                  margin-bottom: 10px;
                  margin-left: 5px;
                  width: 125px;
                  display: inline-block;
                "
              >
                <option value="user">User</option>
                <option value="team">Team</option>
              </select>
            </div>

            <select
              v-if="transfer_type == 'user'"
              v-model="agents_status"
              class="form-control"
              style="
                margin-bottom: 10px;
                margin-left: 5px;
                width: 125px;
                display: inline-block;
              "
              @change="getAgents()"
            >
              <option value="all">All Statuses</option>
              <option value="online">Available</option>
              <option value="oncall">On Call</option>
            </select>

            <select
              v-if="transfer_type == 'user'"
              v-model="team_id"
              class="form-control"
              style="
                margin-bottom: 10px;
                margin-left: 5;
                width: 125px;
                display: inline-block;
              "
              @change="getAgents()"
            >
              <option :value="null">All Teams</option>
              <option
                v-for="option in teams"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </option>
            </select>

            <input
              v-if="transfer_type == 'user'"
              v-model="agents_search"
              type="text"
              class="form-control pull-right"
              style="width: 180px; margin-bottom: 10px; margin-right: 5px"
              placeholder="Search"
              @keyup="getAgents()"
            />

            <table
              v-if="transfer_type == 'user'"
              class="table table-striped table-hover"
              style="margin-bottom: 0; background-color: white"
            >
              <tbody>
                <tr v-if="!agents.length">
                  <td colspan="3" style="background: white; border: none">
                    <p class="text-center">No agents found!</p>
                  </td>
                </tr>
                <tr v-for="agent in agents" :key="agent.id">
                  <td class="text-left" style="padding-left: 10px; width: 30px">
                    <i
                      v-if="agent.on_call_id"
                      title="On A Call"
                      class="material-icons"
                      style="color: #f0ad4e; font-size: 21px; display: block"
                      >do_not_disturb_on</i
                    >
                    <i
                      v-else-if="
                        agent.is_available &&
                        (!agent.is_app_only || agent.is_online_app)
                      "
                      title="Available"
                      class="material-icons"
                      style="color: green; font-size: 21px; display: block"
                      >check_circle</i
                    >
                    <i
                      v-else
                      title="Offline"
                      class="material-icons"
                      style="color: #d9534f; font-size: 21px; display: block"
                      >cancel</i
                    >
                  </td>
                  <td
                    style="
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 150px;
                    "
                  >
                    {{ agent.full_name }}
                  </td>
                  <td class="text-right" style="padding-right: 10px">
                    <i
                      v-if="
                        !agent.on_call_id &&
                        agent.is_available &&
                        (!agent.is_app_only || agent.is_online_app)
                      "
                      class="material-icons"
                      style="
                        float: right;
                        font-size: 22px;
                        color: #5cb85c;
                        cursor: pointer;
                      "
                      @click="startTransfer(agent)"
                      >call</i
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              v-if="transfer_type == 'team'"
              style="height: 350px; overflow-y: scroll"
            >
              <table
                class="table table-striped table-hover"
                style="margin-bottom: 0; background-color: white"
              >
                <tbody>
                  <tr v-if="!teams.length">
                    <td colspan="3" style="background: white; border: none">
                      <p class="text-center">No teams found!</p>
                    </td>
                  </tr>
                  <tr v-for="team in teams" :key="team.id">
                    <td
                      class="text-left"
                      style="padding-left: 10px; width: 30px"
                    >
                      <i
                        v-if="team.is_available"
                        title="Available"
                        class="material-icons"
                        style="color: green; font-size: 21px; display: block"
                        >check_circle</i
                      >
                      <i
                        v-else
                        title="Offline"
                        class="material-icons"
                        style="color: #d9534f; font-size: 21px; display: block"
                        >cancel</i
                      >
                    </td>
                    <td
                      style="
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 150px;
                      "
                    >
                      {{ team.name }}
                    </td>
                    <td class="text-right" style="padding-right: 10px">
                      <i
                        v-if="team.is_available"
                        class="material-icons"
                        style="
                          float: right;
                          font-size: 22px;
                          color: #5cb85c;
                          cursor: pointer;
                        "
                        @click="startTransfer(null, team)"
                        >call</i
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ul
              v-if="transfer_type == 'user'"
              class="pager"
              style="
                padding-left: 20px;
                padding-right: 20px;
                position: absolute;
                right: 0;
                left: 0;
                bottom: 5px;
              "
            >
              <li
                :class="{ disabled: agents_meta.current_page < 2 }"
                class="previous"
              >
                <a
                  style="border-radius: 4px"
                  href="#"
                  rel="previous"
                  @click="getAgents(agents_meta.current_page - 1)"
                  >Previous</a
                >
              </li>
              <li
                :class="{
                  disabled: agents_meta.current_page == agents_meta.last_page,
                }"
                class="next"
              >
                <a
                  style="border-radius: 4px"
                  href="#"
                  rel="next"
                  @click="getAgents(agents_meta.current_page + 1)"
                  >Next</a
                >
              </li>
            </ul>
          </div>
        </div>

        <div v-if="openTab == 'conference'">
          <i
            style="
              font-size: 30px;
              top: 80px;
              position: absolute;
              right: 10px;
              cursor: pointer;
            "
            class="material-icons"
            @click="toggleConference"
            >close</i
          >

          <div
            style="
              background: white;
              padding: 0;
              height: 400px;
              overflow: none;
              margin-bottom: 10px;
            "
          >
            <h3
              class="text-center"
              style="margin-top: 0px; margin-bottom: 20px"
            >
              Conference
            </h3>

            <select
              v-model="agents_status"
              class="form-control"
              style="
                margin-bottom: 5px;
                margin-left: 5px;
                width: 125px;
                display: inline-block;
              "
              @change="getAgents()"
            >
              <option value="all">All Statuses</option>
              <option value="online">Online</option>
              <option value="oncall">On Call</option>
            </select>

            <select
              v-model="team_id"
              class="form-control"
              style="
                margin-bottom: 5px;
                margin-left: 5;
                width: 125px;
                display: inline-block;
              "
              @change="getAgents()"
            >
              <option :value="null">All Teams</option>
              <option
                v-for="option in teams"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </option>
            </select>

            <input
              v-model="agents_search"
              type="text"
              class="form-control pull-right"
              style="width: 180px; margin-bottom: 5px; margin-right: 5px"
              placeholder="Search"
              @keyup="getAgents()"
            />

            <table
              class="table table-striped table-hover"
              style="margin-bottom: 0; background-color: white"
            >
              <tbody>
                <tr v-if="!agents.length">
                  <td colspan="3" style="background: white; border: none">
                    <p class="text-center">No agents found!</p>
                  </td>
                </tr>
                <tr v-for="agent in agents" :key="agent.id">
                  <td class="text-left" style="padding-left: 10px; width: 30px">
                    <i
                      v-if="agent.on_call_id"
                      title="On A Call"
                      class="material-icons"
                      style="color: #f0ad4e; font-size: 21px; display: block"
                      >do_not_disturb_on</i
                    >
                    <i
                      v-else-if="
                        agent.is_available &&
                        (agent.is_online_app || agent.is_mobile)
                      "
                      title="Available"
                      class="material-icons"
                      style="color: green; font-size: 21px; display: block"
                      >check_circle</i
                    >
                    <i
                      v-else
                      title="Offline"
                      class="material-icons"
                      style="color: #d9534f; font-size: 21px; display: block"
                      >cancel</i
                    >
                  </td>
                  <td
                    style="
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 150px;
                    "
                  >
                    {{ agent.full_name }}
                  </td>
                  <td class="text-right" style="padding-right: 10px">
                    <i
                      v-if="
                        agent.on_call_id && user.permissions.includes('listen')
                      "
                      class="material-icons"
                      style="float: right; font-size: 22px; cursor: pointer"
                      @click="startListen(agent.on_call_id)"
                      >hearing</i
                    >
                    <i
                      v-if="
                        agent.on_call_id && user.permissions.includes('whisper')
                      "
                      class="material-icons"
                      style="
                        float: right;
                        font-size: 22px;
                        cursor: pointer;
                        margin-right: 10px;
                      "
                      @click="startWhisper(agent.on_call_id)"
                      >headset_mic</i
                    >
                    <i
                      v-if="
                        agent.on_call_id && user.permissions.includes('barge')
                      "
                      class="material-icons"
                      style="
                        float: right;
                        font-size: 22px;
                        cursor: pointer;
                        margin-right: 10px;
                      "
                      @click="startBarge(agent.on_call_id)"
                      >call</i
                    >
                    <i
                      v-if="
                        !agent.on_call_id &&
                        agent.is_available &&
                        (agent.is_online_app || agent.is_mobile)
                      "
                      class="material-icons"
                      style="
                        float: right;
                        font-size: 22px;
                        color: #5cb85c;
                        cursor: pointer;
                      "
                      @click="startConference(agent)"
                      >call</i
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <ul
              class="pager"
              style="
                padding-left: 20px;
                padding-right: 20px;
                position: absolute;
                right: 0;
                left: 0;
                bottom: 65px;
              "
            >
              <li
                :class="{ disabled: agents_meta.current_page < 2 }"
                class="previous"
              >
                <a
                  style="border-radius: 4px"
                  href="#"
                  rel="previous"
                  @click="getAgents(agents_meta.current_page - 1)"
                  >Previous</a
                >
              </li>
              <li
                :class="{
                  disabled: agents_meta.current_page == agents_meta.last_page,
                }"
                class="next"
              >
                <a
                  style="border-radius: 4px"
                  href="#"
                  rel="next"
                  @click="getAgents(agents_meta.current_page + 1)"
                  >Next</a
                >
              </li>
            </ul>
          </div>
        </div>

        <div v-if="openTab == 'settings'">
          <i
            style="
              font-size: 30px;
              top: 80px;
              position: absolute;
              right: 10px;
              cursor: pointer;
            "
            class="material-icons"
            @click="toggleSettings"
            >close</i
          >
          <h3 class="text-center" style="margin-top: 0px; margin-bottom: 30px">
            Settings
          </h3>

          <form
            role="form"
            style="padding-left: 30px; padding-right: 30px; height: 400px"
            @submit.prevent="updateAudio"
          >
            <h4>Microphone</h4>
            <select v-model="audio.microphoneDevice" class="form-control">
              <option
                v-for="option in audio.input"
                :key="option.deviceId"
                :value="option.deviceId"
              >
                {{ option.label }}
              </option>
            </select>

            <h4>Speaker</h4>
            <select v-model="audio.speakerDevice" class="form-control">
              <option
                v-for="option in audio.output"
                :key="option.deviceId"
                :value="option.deviceId"
              >
                {{ option.label }}
              </option>
            </select>

            <h4>Ringer</h4>
            <select v-model="audio.ringtoneDevice" class="form-control">
              <option
                v-for="option in audio.output"
                :key="option.deviceId"
                :value="option.deviceId"
              >
                {{ option.label }}
              </option>
            </select>

            <div v-if="user.permissions.includes('app-autoaccept')">
              <h4>Auto Accept</h4>
              <div class="material-switch">
                <span class="small-label" style="padding-right: 6px">OFF</span>
                <input
                  id="autoaccept"
                  v-model="autoaccept"
                  value="1"
                  type="checkbox"
                />
                <label
                  for="autoaccept"
                  class="label-success"
                  style="
                    background-color: #167296;
                    position: relative;
                    top: 0;
                    left: 0;
                    pointer-events: all;
                  "
                />
                <span class="small-label" style="padding-left: 3px">ON</span>
              </div>
            </div>

            <h4>Version</h4>
            <p style="font-size: 12px">v{{ version }}</p>

            <div class="text-right" style="margin-top: 20px">
              <button
                type="submit"
                class="btn btn-primary"
                style="padding: 10px 20px; border-radius: 2px; border: none"
              >
                Update
              </button>
            </div>
          </form>
        </div>

        <div v-if="openTab == 'history'">
          <i
            style="
              font-size: 30px;
              top: 80px;
              position: absolute;
              right: 10px;
              cursor: pointer;
            "
            class="material-icons"
            @click="toggleHistory"
            >close</i
          >
          <h3 class="text-center" style="margin-top: 0px; margin-bottom: 20px">
            Call History
          </h3>
          <table
            class="table table-striped table-hover"
            style="margin-bottom: 0; background-color: white"
          >
            <tbody>
              <tr v-if="!calls.length">
                <td colspan="7">
                  <p class="text-center">You don't have any calls!</p>
                </td>
              </tr>
              <tr v-for="call in calls" :key="call.id" style="height: 72px">
                <td class="text-left" style="position: relative">
                  <i
                    class="material-icons"
                    style="font-size: 16px; margin-top: 2px"
                    >{{
                      call.direction == "outbound"
                        ? "call_made"
                        : "call_received"
                    }}</i
                  >
                  <audio
                    v-if="call.recording_url"
                    controls
                    class="players"
                    style="
                      height: 29px;
                      width: 143px;
                      position: absolute;
                      left: 10px;
                      bottom: 8px;
                    "
                  >
                    <source :src="call.recording_url" type="audio/mpeg" />
                  </audio>
                </td>
                <td>
                  {{ call.time_formatted }}
                  <div
                    v-if="call.lead_retry"
                    style="font-size: 9px; padding-top: 3px"
                    class="small-label"
                  >
                    Followup #{{ call.lead_retry }}
                  </div>
                </td>
                <td>
                  <div v-if="call.status == 'missed'">Missed</div>
                  <div v-else-if="call.status == 'busy'">Busy</div>
                  <div v-else-if="call.status == 'offline'">Offline</div>
                  <div v-else-if="call.status == 'in-progress'">
                    In Progress
                  </div>
                  <div v-else-if="call.status == 'ringing'">Ringing</div>
                  <div v-else-if="!call.status" />
                  <div v-else-if="call.seconds == 0">No Answer</div>
                  <div v-else>
                    {{ call.duration }}
                  </div>

                  <div
                    v-if="call.retry"
                    style="font-size: 9px; padding-top: 3px"
                    class="small-label"
                  >
                    Retry #{{ call.retry }}
                  </div>

                  <div v-if="call.status == 'missed'">No agents answered</div>
                  <div v-else-if="call.status == 'offline'">
                    Outside availability hours
                  </div>
                  <div v-else-if="call.status == 'busy'">All agents busy</div>
                  <div v-if="call.user">
                    {{ call.user.name }}
                  </div>
                </td>
                <td>
                  <div v-if="user.account_id == 6671 && call.lead">
                    {{ call.lead.fname }} {{ call.lead.lname }}
                  </div>
                  <div v-else-if="call.lead">
                    <a href="#" @click="toggleNewCall(call.lead)">{{
                      call.lead.label
                    }}</a
                    ><br />
                    <span v-if="call.lead.fname">{{
                      call.lead.phone_number_formatted
                    }}</span>
                  </div>
                  <div v-else>
                    {{ call.phone_number_formatted }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <ul
            class="pager"
            style="
              padding-left: 20px;
              padding-right: 20px;
              padding-top: 14px;
              position: absolute;
              right: 0;
              left: 0;
              bottom: 65px;
            "
          >
            <li :class="{ disabled: calls_page < 2 }" class="previous">
              <a
                style="border-radius: 4px"
                href="#"
                rel="previous"
                @click="getCalls(calls_page - 1)"
                >Previous</a
              >
            </li>
            <li class="next">
              <a
                style="border-radius: 4px"
                href="#"
                rel="next"
                @click="getCalls(calls_page + 1)"
                >Next</a
              >
            </li>
          </ul>
        </div>

        <div v-if="isConferenceLeadOpen">
          <i
            style="
              font-size: 30px;
              top: 80px;
              position: absolute;
              right: 10px;
              cursor: pointer;
            "
            class="material-icons"
            @click="toggleConferenceLead"
            >close</i
          >
          <form
            role="form"
            style="padding: 30px"
            @submit.prevent="callConferenceLead"
          >
            <h3
              class="text-center"
              style="margin-top: 0px; margin-bottom: 30px"
            >
              Add Lead
            </h3>

            <div
              v-if="error_message"
              class="help-block text-center"
              style="color: #a94442; margin-top: 0px; margin-bottom: 10px"
            >
              <strong>{{ error_message }} </strong>
            </div>

            <div class="row">
              <div class="col-xs-6">
                <div class="form-group">
                  <label for="name" class="control-label">First Name</label>

                  <input
                    id="fname"
                    v-model="fname"
                    style="font-size: 18px; padding: 22px 10px"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-xs-6">
                <div class="form-group">
                  <label for="name" class="control-label">Last Name</label>

                  <input
                    id="lname"
                    v-model="lname"
                    style="font-size: 18px; padding: 22px 10px"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="name" class="control-label">Phone Number</label>
                  <div class="clearfix">
                    <vue-tel-input
                      v-model="phone_number"
                      :default-country="user.country_code"
                      :enabled-country-code="true"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="form-group clearfix"
              style="margin-top: 20px; margin-bottom: 0"
            >
              <button
                id="submit"
                type="submit"
                class="btn btn-primary pull-right"
                style="padding: 10px 20px; border-radius: 2px; border: none"
              >
                Call
              </button>
            </div>
          </form>
        </div>

        <div v-if="openTab == 'new_call'">
          <i
            style="
              font-size: 30px;
              top: 80px;
              position: absolute;
              right: 10px;
              cursor: pointer;
            "
            class="material-icons"
            @click="toggleNewCall"
            >close</i
          >
          <h3 class="text-center" style="margin-top: 0px; margin-bottom: 30px">
            New Call
          </h3>

          <form
            role="form"
            style="padding-left: 30px; padding-right: 30px; height: 400px"
            @submit.prevent="makeCall"
          >
            <div
              v-if="error_message"
              class="help-block text-center"
              style="color: #a94442; margin-top: 0px; margin-bottom: 10px"
            >
              <strong>{{ error_message }} </strong>
            </div>

            <div class="row">
              <div class="col-xs-6">
                <div class="form-group">
                  <label for="name" class="control-label">First Name</label>

                  <input
                    id="fname"
                    v-model="fname"
                    style="font-size: 18px; padding: 22px 10px"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="col-xs-6">
                <div class="form-group">
                  <label for="name" class="control-label">Last Name</label>

                  <input
                    id="lname"
                    v-model="lname"
                    style="font-size: 18px; padding: 22px 10px"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="name" class="control-label">Phone Number</label>
                  <div class="clearfix">
                    <vue-tel-input
                      v-model="phone_number"
                      :default-country="user.country_code"
                      :enabled-country-code="true"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="form-group clearfix"
              style="margin-top: 20px; margin-bottom: 0"
            >
              <button
                id="submit"
                type="submit"
                class="btn btn-primary pull-right"
                style="padding: 10px 20px; border-radius: 2px; border: none"
                :disabled="isMakingCall"
              >
                Call Now
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      v-if="user && !isTransferOpen && !isConferenceLeadOpen && !openTab"
      style="position: absolute; bottom: 10px; right: 0; left: 0"
    >
      <div v-if="status == 'oncall'" style="width: fit-content; margin: auto">
        <div style="float: left; margin-right: 10px">
          <i
            class="material-icons"
            style="
              float: left;
              color: #073b4c;
              font-size: 12px;
              margin-right: 2px;
            "
            >headset</i
          >
          <div
            v-for="index in 5"
            :key="index"
            style="
              width: 7px;
              height: 5px;
              margin-top: 2px;
              float: left;
              border-radius: 2px;
              margin-left: 1px;
            "
            :style="{
              backgroundColor:
                index < audio.outputVolume ? '#5cb85c' : '#D3D3D3',
            }"
          />
        </div>

        <div style="float: left">
          <i
            class="material-icons"
            style="float: left; color: #073b4c; font-size: 12px"
            >mic</i
          >
          <div
            v-for="index in 5"
            :key="index"
            style="
              width: 7px;
              height: 5px;
              margin-top: 2px;
              float: left;
              border-radius: 2px;
              margin-left: 1px;
            "
            :style="{
              backgroundColor:
                index < audio.inputVolume ? '#5cb85c' : '#D3D3D3',
            }"
          />
        </div>
      </div>
    </div>

    <nav
      v-if="user && (status == 'waiting' || status == 'thankyou')"
      class="mobile-bottom-nav"
    >
      <div class="mobile-bottom-nav__item" @click="toggleNewCall()">
        <div class="mobile-bottom-nav__item-content">
          <i class="material-icons">phone</i>
          Call
        </div>
      </div>
      <div
        class="mobile-bottom-nav__item"
        @click="toggleConference()"
        style="display: none"
      >
        <div class="mobile-bottom-nav__item-content">
          <i class="material-icons">people</i>
          Conference
        </div>
      </div>
      <div class="mobile-bottom-nav__item" @click="toggleHistory()">
        <div class="mobile-bottom-nav__item-content">
          <i class="material-icons">history</i>
          History
        </div>
      </div>

      <div class="mobile-bottom-nav__item" @click="toggleSettings()">
        <div class="mobile-bottom-nav__item-content">
          <i class="material-icons">settings</i>
          Settings
        </div>
      </div>
    </nav>
  </main>
</template>
<script>
import axios from "axios";
import { Device } from "twilio-client";
import Pusher from "pusher-js";
import "bootstrap/dist/css/bootstrap.min.css";
import "material-icons/iconfont/material-icons.css";

if (/electron/i.test(navigator.userAgent)) {
  window.ipcRenderer = window.require("electron").ipcRenderer;
}

if (document.location.search.includes("dev=true")) {
  axios.defaults.baseURL = "https://callingly.dev";
} else {
  axios.defaults.baseURL = "https://callingly.com";
}

axios.defaults.withCredentials = true;

export default {
  components: {},
  data() {
    return {
      version: "1.5.0",
      loading: true,
      online: true,
      agents: [],
      agents_meta: {},
      agents_status: "online",
      agents_search: null,
      agent_id: null,
      pusher: null,
      teams: [],
      team_id: null,
      call: {},
      call_user: {},
      call_user_id: null,
      calls: [],
      calls_page: 1,
      error_message: null,
      user: null,
      device: null,
      message: "Connecting...",
      status: "waiting",
      seconds: -1,
      timer: null,
      lead: {
        profile_picture:
          "https://ui-avatars.com/api/%20/160/81D5F2/073B4C/2/0.45",
      },
      isMuted: false,
      isDialpadOpen: false,
      isTransferOpen: false,
      isDropdownOpen: false,
      isConferenceLeadOpen: false,
      isMakingCall: false,
      openTab: null,
      email: null,
      password: null,
      fname: null,
      lname: null,
      phone_number: null,
      userPresence: null,
      autoaccept: false,
      transfer_type: "user",
      audio: {
        input: [],
        output: [],
        microphoneDevice: null,
        speakerDevices: null,
        ringtoneDevices: null,
        inputVolume: 0,
        outputVolume: 0,
      },
      smsbody: "Sorry we couldn't reach you!",
    };
  },
  created() {
    var self = this;
    this.getUser();
    this.createDevice();
    const url = new URLSearchParams(window.location.search).get("url");
    if (url && url.startsWith("tel:/")) {
      const parsedUrl = url.replace(/[^+0-9]/g, "");
      self.toggleNewCall("", parsedUrl);
    }

    Pusher.Runtime.createXHR = function () {
      var xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      return xhr;
    };

    self.pusher = new Pusher("62276ce0f651d8ab5e54", {
      authEndpoint: "https://callingly.com/api/v1/pusher/auth",
      cluster: "us2",
      forceTLS: true,
    });

    window.addEventListener("online", this.setOnline);
    window.addEventListener("offline", this.setOffline);
  },
  methods: {
    setOnline() {
      this.online = true;
    },
    setOffline() {
      this.online = false;
    },
    login() {
      var self = this;

      axios
        .post("/api/v1/user/login", {
          email: self.email,
          password: self.password,
        })
        .then(function (response) {
          if (response.data.success) {
            location.reload();
          } else {
            self.error_message = "Your login credentials are invalid.";
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getUser() {
      var self = this;

      axios
        .get("/api/v1/user")
        .then(function (response) {
          if (response.data.success) {
            self.user = response.data;
            self.startPhone();
            self.subscribe();
            self.getAgents();

            axios.defaults.headers.common["X-CSRF-TOKEN"] =
              self.user.csrf_token;

            axios.interceptors.response.use(
              function (response) {
                return response;
              },
              function (error) {
                if (
                  error.response &&
                  typeof error.response !== "undefined" &&
                  401 === error.response.status
                ) {
                  location.reload();
                } else {
                  return Promise.reject(error);
                }
              }
            );
          } else {
            self.status = "login";
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    startPhone() {
      var self = this;

      axios
        .get("/api/v1/twilio/token2")
        .then(function (response) {
          self.device.setup(response.data.token, {
            codecPreferences: ["opus", "pcmu"],
            fakeLocalDTMF: true,
            enableRingingState: true,
            sounds: {
              incoming: "https://cdn.callingly.com/mp3s/us-ring2.mp3", // Ring with slight delay
            },
          });

          self.device.audio.disconnect(false); // Disable the disconnect sound
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    createDevice() {
      var self = this;

      self.device = new Device();

      self.device.on("ready", function (device) {
        //self.device.audio.setInputDevice('default');

        self.audio.input = [];
        self.audio.output = [];

        self.device.audio.availableInputDevices.forEach(function (device, id) {
          if (id == "default") {
            self.device.audio.setInputDevice("default");
          }

          self.audio.input.push(device);
        });

        self.device.audio.availableOutputDevices.forEach(function (device, id) {
          self.audio.output.push(device);
        });

        self.reset();
      });

      self.device.on("error", function (error) {
        if (error.code == 31009 || error.code == 31005) {
          return;
        }

        self.reset();
        if (error.code == 31208 || error.code == 31201) {
          alert("Callingly is not able to access your microphone.");
        }

        if (self.user.account_id == 781) {
          return;
        }

        //if (self.user.account_id == 6552) {
        if (error.code == 31205) {
          self.startPhone();
        } else {
          alert("Error code: " + error.code);
        }
        //}

        // axios.post('/twilio/debug/webrtc', {
        // 	user: self.user,
        // 	error: error
        // }).catch(function(err) {
        //   console.log(err);
        // });
      });

      self.device.on("offline", function () {
        if (self.user) {
          self.reset();
          self.startPhone();
        }
      });

      self.device.on("incoming", function (connection) {
        self.connection = connection;

        connection.on("cancel", function () {
          self.reset();
        });

        connection.on("error", function (warning) {
          self.reset();

          //if (self.user.account_id == 6552) {
          alert("Incoming call error 7");
          //}
        });

        connection.on("warning", function (warning) {
          // axios.post('/twilio/debug/webrtc', {
          // 	user_id: self.user.id,
          // 	call_id: self.call.id,
          // 	warning: warning
          // }).catch(function(err) {
          //   console.log(err);
          // });
        });

        if (
          self.user.account_id == 6461 &&
          (self.status == "disposition" || self.status == "disposition2")
        ) {
          self.connection.reject();
        } else {
          self.incomingLead(connection.parameters.From.replace("client:", ""));
        }
      });

      self.device.on("connect", function (connection) {
        if (self.call_user.type == "listen") {
          self.mute();
        }

        connection.on("cancel", function () {
          self.reset();
        });

        connection.on("error", function (error) {
          self.reset();

          //if (self.user.account_id == 6552) {
          alert("Connection error code 8");
          //}

          // axios.post('/twilio/debug/webrtc', {
          // 	user: self.user,
          // 	error: error
          // }).catch(function(err) {
          //   console.log(err);
          // });
        });

        connection.on("disconnect", function (conn) {
          self.isDialpadOpen = false;
          self.isMuted = false;

          if (self.connection && self.connection.status() != "closed") {
            axios.post("/twilio/debug/webrtc", {
              user: self.user,
              conn_status: self.connection.status(),
            });
          }

          clearInterval(self.timer);
          self.message = "Call Ended";

          if (
            self.call.direction == "inbound" ||
            self.call_user.type == "listen" ||
            self.call_user.type == "whisper"
          ) {
            self.status = "thankyou";
            setTimeout(function () {
              if (self.status == "thankyou") {
                self.reset();
              }
            }, 2000);
          } else {
            self.status = "disposition";
          }
        });

        connection.on("volume", function (inputVolume, outputVolume) {
          self.audio.inputVolume = inputVolume * 10;
          self.audio.outputVolume = outputVolume * 20;
        });
      });
    },
    reset() {
      var self = this;

      if (self.connection && self.connection.status() != "closed") {
        //self.connection.disconnect();
      }

      clearInterval(self.timer);
      self.message = "";

      self.call = {};
      self.call_user = {};

      self.isMuted = false;
      self.isDialpadOpen = false;
      self.isTransferOpen = false;
      self.status = "waiting";
      self.lead = {
        profile_picture:
          "https://ui-avatars.com/api/%20/160/81D5F2/073B4C/2/0.45",
      };
    },
    accept() {
      var self = this;
      if (self.connection.direction != "OUTGOING") {
        self.connection.accept();
      }

      if (
        self.call_user.type == "listen" ||
        self.call_user.type == "whisper" ||
        self.call_user.type == "barge"
      ) {
        self.seconds = self.call.seconds_since_started_at;
      } else {
        self.seconds = -1;
      }

      self.timerFunction();
      clearInterval(self.timer);
      self.timer = setInterval(self.timerFunction, 1000);
      self.status = "oncall";
    },
    reject() {
      var self = this;

      clearInterval(self.timer);

      self.connection.reject();
      self.reset();

      axios
        .post("/apps/webrtc/" + self.call.id + "/reject")
        .catch(function (err) {
          console.log(err);
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    thankyou() {
      var self = this;

      self.status = "thankyou";
      setTimeout(function () {
        if (self.status == "thankyou") {
          self.reset();
        }
      }, 2000);
    },
    disposition(status) {
      var self = this;

      axios
        .post("/apps/webrtc/" + self.call.id + "/disposition", {
          status,
        })
        .catch(function (err) {
          console.log(err);
        });

      if (
        self.user.permissions.includes("app-sms") &&
        (status == "voicemail" || status == "missed")
      ) {
        self.status = "sms";
      } else if (
        self.call.profile.is_deal_whispertext &&
        status == "contacted"
      ) {
        self.status = "disposition2";
      } else {
        self.thankyou();
      }
    },
    disposition2(key) {
      var self = this;

      axios
        .post("/apps/webrtc/" + self.call.id + "/disposition2", {
          key,
        })
        .catch(function (err) {
          console.log(err);
        });

      self.thankyou();
    },
    check() {
      var self = this;

      axios.post("/apps/webrtc/check").catch(function (err) {
        console.log(err);
      });
    },
    getSMSMessage() {
      var self = this;
      self.smsbody = "Sorry we couldn't reach you!";

      return;
    },
    showsms() {
      var self = this;
      self.status = "sms";
    },
    sendsms() {
      var self = this;
      self.thankyou();
      axios
        .post("/apps/webrtc/" + self.call.id + "/sms", {
          smsbody: self.smsbody,
        })
        .then(function (response) {
          if (response.data.success) {
            self.smsbody = "Sorry we couldn't reach you!";
            self.thankyou();
          } else {
            self.error_message = response.data.error;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    logout() {
      var self = this;

      axios.post("/api/v1/user/logout").catch(function (err) {
        console.log(err);
      });

      self.pusher.unsubscribe("presence-app-" + self.user.id);
      self.user = null;
      if (self.device) {
        self.device.destroy();
      }
      self.status = "login";
      self.closeDropdown();
    },
    timerFunction() {
      var self = this;

      self.seconds++;

      var min = Math.floor(self.seconds / 60);
      var sec = self.seconds - min * 60;

      if (min < 10) {
        min = "0" + min;
      }
      if (sec < 10) {
        sec = "0" + sec;
      }

      self.message = min + ":" + sec;
    },
    toggleSettings() {
      var self = this;

      if (self.openTab == "settings") {
        self.openTab = null;
      } else {
        self.openTab = "settings";

        self.audio.input = [];
        self.audio.output = [];

        self.device.audio.availableInputDevices.forEach(function (device, id) {
          self.audio.input.push(device);
        });

        self.device.audio.availableOutputDevices.forEach(function (device, id) {
          self.audio.output.push(device);
        });

        self.audio.microphoneDevice =
          self.device.audio.inputDevice.deviceId || false;
        if (!self.audio.microphoneDevice) {
          self.audio.microphoneDevice = "default";
        }

        self.audio.speakerDevice =
          self.device.audio.speakerDevices.get().values().next().value
            .deviceId || false;
        if (!self.audio.speakerDevice) {
          self.audio.speakerDevice = "default";
        }

        self.audio.ringtoneDevice =
          self.device.audio.ringtoneDevices.get().values().next().value
            .deviceId || false;
        if (!self.audio.ringtoneDevice) {
          self.audio.ringtoneDevice = "default";
        }
      }
    },
    updateAudio() {
      var self = this;

      self.device.audio.setInputDevice(self.audio.microphoneDevice);
      self.device.audio.speakerDevices.set(self.audio.speakerDevice);
      self.device.audio.ringtoneDevices.set(self.audio.ringtoneDevice);

      self.openTab = null;
    },
    toggleHistory() {
      var self = this;

      if (self.openTab == "history") {
        self.openTab = null;
      } else {
        self.openTab = "history";
        self.getCalls();
      }
    },
    toggleNewCall(lead, url) {
      var self = this;
      self.error_message = null;
      if (lead) {
        self.fname = lead.fname;
        self.lname = lead.lname;
        self.phone_number = lead.phone_number;
      } else if (url) {
        self.fname = null;
        self.lname = null;
        self.phone_number = url;
      } else {
        self.fname = null;
        self.lname = null;
        self.phone_number = null;
      }

      if (self.openTab == "new_call") {
        self.openTab = null;
      } else {
        self.openTab = "new_call";
      }
    },
    toggleDialpad() {
      var self = this;
      self.isDialpadOpen = !self.isDialpadOpen;

      if (self.isDialpadOpen) {
        self.isConferenceLeadOpen = false;
        self.isTransferOpen = false;
      }
    },
    toggleTransfer() {
      var self = this;

      self.isTransferOpen = !self.isTransferOpen;
      if (self.isTransferOpen) {
        self.getTeams();
        self.getAgents();
        self.isDialpadOpen = false;
      }
    },
    toggleConferenceLead() {
      var self = this;
      self.isConferenceLeadOpen = !self.isConferenceLeadOpen;

      if (self.isConferenceLeadOpen) {
        self.isDialpadOpen = false;
      }
    },
    toggleConference() {
      var self = this;

      if (self.openTab == "conference") {
        self.openTab = null;
      } else {
        self.openTab = "conference";
        self.getTeams();
        self.getAgents();
      }
    },
    startConference(agent) {
      var self = this;

      axios
        .post("/api/v1/calls/conference", {
          user_id: agent.id,
        })
        .then(function (response) {
          if (response.data.success) {
            self.openTab = null;
            self.lead = {
              profile_picture:
                "https://ui-avatars.com/api/%20/160/81D5F2/073B4C/2/0.45",
              name: "Conference",
            };
          } else {
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    startTransfer(agent, team) {
      var self = this;
      var request = {
        call_user_id: self.call_user_id,
      };

      if (agent) {
        agent.on_call_id = 1;
        request["user_id"] = agent.id;
      } else if (team) {
        request["team_id"] = team.id;
      }

      axios
        .post("/api/v1/calls/" + self.call.id + "/transfer", request)
        .then(function (response) {
          if (response.data.success) {
            self.isTransferOpen = false;
          } else {
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    startListen(call_id) {
      var self = this;

      axios
        .post("/api/v1/calls/" + call_id + "/listen", {})
        .then(function (response) {
          if (response.data.success) {
            self.openTab = null;
          } else {
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    startWhisper(call_id) {
      var self = this;

      axios
        .post("/api/v1/calls/" + call_id + "/whisper", {})
        .then(function (response) {
          if (response.data.success) {
            self.openTab = null;
          } else {
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    startBarge(call_id) {
      var self = this;

      axios
        .post("/api/v1/calls/" + call_id + "/barge", {})
        .then(function (response) {
          if (response.data.success) {
            self.openTab = null;
          } else {
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    toggleDropdown(event) {
      var self = this;
      self.isDropdownOpen = !self.isDropdownOpen;
    },
    closeDropdown() {
      var self = this;
      self.isDropdownOpen = false;
    },
    hangup() {
      var self = this;
      if (self.connection.status() != "open") {
        self.reset();
      }
      self.isTransferOpen = false;
      self.connection.disconnect();
    },
    mute() {
      var self = this;
      self.isMuted = true;
      self.connection.mute(true);
    },
    unmute() {
      var self = this;
      self.isMuted = false;
      self.connection.mute(false);
    },
    digitPress(digit) {
      var self = this;
      self.connection.sendDigits(digit);
    },
    subscribe() {
      var self = this;

      self.userPresence = self.pusher.subscribe("presence-app-" + self.user.id);

      self.userPresence.bind("pusher:subscription_succeeded", function () {
        console.log("Pusher live");
      });

      self.userPresence.bind("pusher:subscription_error", function () {
        console.log("Pusher error");
      });
    },
    incomingLead(call_user_id) {
      var self = this;
      axios
        .get("/api/v1/callusers/" + call_user_id)
        .then(function (response) {
          self.call = response.data.call;
          self.call_user = response.data;
          self.call_user_id = call_user_id;

          if (self.connection.direction == "OUTGOING") {
            self.accept();
            self.message = "Dialing...";
          } else {
            if (self.call.type == "conference") {
              self.lead = {
                profile_picture:
                  "https://ui-avatars.com/api/%20/160/81D5F2/073B4C/2/0.45",
                name: "Conference",
              };
            } else {
              self.lead = response.data.call.lead;

              if (self.user.permissions.includes("app-sms")) {
                self.getSMSMessage();
              }
            }

            if (/electron/i.test(navigator.userAgent)) {
              ipcRenderer.invoke("incoming-call");
            }

            self.status = "ringing";

            if (self.call.direction == "inbound") {
              self.message = self.call.number.name;
            } else if (self.call.type == "transfer") {
              self.message = "Incoming Transfer!";
            } else if (self.call.type == "conference") {
              self.message = "Incoming Conference!";
            } else {
              self.message = "Incoming Lead!";
            }

            if (self.autoaccept && self.call.profile_id != 7703) {
              self.accept();
            }

            if (response.data.is_autoaccept) {
              self.accept();
            }
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    retry() {
      var self = this;

      if (self.lead) {
        axios
          .post("/api/v1/calls/twilio", {
            fname: self.lead.fname,
            lname: self.lead.lname,
            phone_number: self.lead.phone_number,
          })
          .then(function (response) {
            if (response.data.success) {
              self.status = "waiting";

              self.lead = response.data.call.lead;
            } else {
              self.error_message = response.data.error;
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      }
    },
    callConferenceLead() {
      var self = this;

      axios
        .post("/api/v1/calls/conferencelead", {
          call_id: self.call.id,
          fname: self.fname,
          lname: self.lname,
          phone_number: self.phone_number,
        })
        .then(function (response) {
          if (response.data.success) {
            self.toggleConferenceLead();
            self.call = response.data.call;
            self.call_user = response.data;
            self.lead = response.data.lead;
          } else {
            self.error_message = response.data.error;
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    makeCall() {
      var self = this;
      self.isMakingCall = true;

      axios
        .post("/api/v1/calls/twilio/outbound", {
          fname: self.fname,
          lname: self.lname,
          phone_number: self.phone_number,
          agent_id: self.agent_id,
          interface: "desktop",
        })
        .then(function (response) {
          if (response.data.success) {
            self.toggleNewCall();
            self.agent_id = null;
            self.incomingLead(response.data.call_user_id);
            self.lead = response.data.call.lead;
            self.connection = self.device.connect({
              call_user_id: response.data.call_user_id,
              from: "from",
              to: response.data.call.phone_number,
            });
          } else {
            self.error_message = response.data.error;
          }
          self.isMakingCall = false;
        })
        .catch(function (err) {
          console.log(err);
          self.isMakingCall = false;
        });
    },
    getCalls(page = 1) {
      var self = this;

      if (page < 1) {
        return;
      }

      axios
        .get("/api/v1/mycalls?limit=5", { params: { page: page } })
        .then(function (response) {
          self.calls = response.data.data;
          self.calls_page = page;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getAgents(page = 1) {
      var self = this;

      if (page < 1) {
        return;
      }

      axios
        .get("/api/v1/users/paginated", {
          params: {
            page: page,
            status: self.agents_status,
            search: self.agents_search,
            team_id: self.team_id,
          },
        })
        .then(function (response) {
          self.agents = response.data.data;
          self.agents_meta = response.data.meta;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getTeams() {
      var self = this;

      axios
        .get("/api/v1/teams")
        .then(function (response) {
          self.teams = response.data.data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.main-container {
  background: url("./assets/background.png");
}

.btn-sign-up {
  width: 100%;
  padding: 15px 18px;
  border: none;
  font-size: 14px;
}

.btn-sign-up-google {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #636363;
  outline: none;
  border: none;
  border-radius: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.btn-sign-up-google__img {
  position: absolute;
  left: 2rem;
  width: 20px;
}

.navbar {
  min-height: 70px;
  margin-bottom: 0;
}

.navbar-brand {
  float: left;
  cursor: default;
}

.navbar-default {
  border-color: transparent;
  background: #073b4c;
}

.navbar-right {
  float: right;
}

.form-control {
  border-radius: 0;
}

.navbar-default .navbar-nav > li > a {
  text-transform: uppercase;
  letter-spacing: 1.4px;
}

.pager {
  margin: 0;
}

[v-cloak] {
  display: none;
}

.callingly-button-color {
  background-color: #32a8d9;
  border-color: #32a8d9;
}

.small-label {
  color: #808080;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
}

.btn-disposition,
.btn-tag {
  font-size: 15px;
  color: #073b4c;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 9px;
}

.btn-number {
  font-family: "Lato", sans-serif;
  font-size: 21px;
  color: #073b4c;
  border: solid 1px #073b4c;
  margin: 3px;
  position: relative;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  background: transparent;
  padding: 12px;
}

.btn-dialer {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid #073b4c;
  margin: auto;
}

.disabled > .btn-dialer {
  border: 2px solid #d3d3d3;
}

.disabled > .btn-dialer > .material-icons,
.disabled > p {
  color: #d3d3d3 !important;
}

.btn-number:hover {
  color: #57bbe4;
  border: 1px solid #57bbe4;
}

body {
  color: #073b4c;
  overflow: hidden;
}

.disabled {
  pointer-events: none;
}

.row {
  height: initial;
}

.vue-tel-input {
  background: white;
}

.material-switch {
  margin-top: 3px;
}
.material-switch > input[type="checkbox"] {
  display: none;
}
.material-switch > label {
  cursor: pointer;
  height: 0;
  position: relative;
  width: 40px;
}
.material-switch > label::before {
  background: #000;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: "";
  height: 16px;
  margin-top: -8px;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px;
}
.material-switch > label::after {
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  content: "";
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}
.material-switch > input[type="checkbox"]:checked + label::before {
  background: inherit;
  opacity: 0.5;
}
.material-switch > input[type="checkbox"]:checked + label::after {
  background: inherit;
  left: 20px;
}

.add-triangle:after {
  position: absolute;
  top: -8px;
  right: 15px;
  display: inline-block;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  border-left: 10px solid transparent;
  content: "";
}

.dropdown-menu > .active > a,
.dropdown-menu > .active:hover > a {
  color: #333;
  background-color: #ebebeb;
}

nav.navbar-fixed-bottom li.active a {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.dropdown-menu-right {
  right: -10px;
}

.form-group {
  position: relative;
  margin-bottom: 35px;
}
input {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
}
input:focus {
  outline: none;
}

.login label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

input:focus ~ label,
input:valid ~ label {
  top: -20px;
  font-size: 14px;
  color: #003c4d;
}

.bar {
  position: relative;
  display: block;
  width: 100%;
}
.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #003c4d;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.bar:before {
  left: 50%;
}
.bar:after {
  right: 50%;
}

/* active state */
input:focus ~ .bar:before,
input:focus ~ .bar:after {
  width: 50%;
}

*:focus {
  outline: none;
}

#profile.online {
  border: 2px solid #00b300;
}

#profile.offline {
  border: 2px solid #dc143c;
}

.mobile-bottom-nav {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  will-change: transform;
  transform: translateZ(0);
  display: flex;
  height: 60px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%);
  background-color: #fff;
}
.mobile-bottom-nav__item {
  flex-grow: 1;
  text-align: center;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.mobile-bottom-nav__item .material-icons {
  margin-bottom: 5px;
}

.mobile-bottom-nav__item--active {
  color: red;
}
.mobile-bottom-nav__item-content {
  display: flex;
  flex-direction: column;
}
</style>
