import Vue from 'vue'
import App from './App.vue'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.use(VueTelInput, {
  dropdownOptions: { showDialCodeInSelection: true }
});

new Vue({
  render: h => h(App),
}).$mount('#app')
